import { RouteRecordRaw } from 'vue-router'

export const sellerRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'landing',
    component: () => import('@/app/layouts/seller-layout.vue'),
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'seller-dashboard',
        component: () => import('@/app/views/seller-page/dashboard/dashboard.vue'),
      },
      {
        path: 'balance',
        name: 'seller-balance',
        component: () => import('@/app/views/seller-page/balance/index.vue'),
      },
      {
        path: 'orders',
        name: 'seller-orders',
        component: () => import('@/app/views/seller-page/orders/index.vue'),
      },
      {
        path: 'orders/:id',
        name: 'seller-order-detail-msite',
        component: () => import('@/app/views/seller-page/orders/detail.vue'),
      },
      {
        path: 'orders/:id/tracking',
        name: 'seller-order-detail-msite-tracking',
        component: () => import('@/app/views/seller-page/orders/tracking.vue'),
      },
      {
        path: 'orders/create-seller-checkout',
        name: 'order-seller-checkout',
        component: () => import('@/app/views/seller-page/orders/components/seller-checkout.vue'),
      },
      {
        path: 'link-checkout',
        name: 'seller-link-checkout',
        component: () => import('@/app/views/seller-page/link-checkout/desktop/index.vue'),
      },
      {
        path: 'ad-integrations',
        name: 'ad-integrations',
        component: () => import('@/app/views/seller-page/ad-integrations/index.vue'),
      },
      {
        path: 'link-checkout/embed/preview',
        name: 'seller-link-checkout-embed-preview',
        component: () => import('@/app/views/seller-page/embed-product/PreviewEmbed.vue'),
      },
      {
        path: 'link-checkout/create',
        name: 'seller-link-checkout-create',
        meta: {
          activity: 'create',
        },
        component: () => import('@/app/views/seller-page/link-checkout/desktop/Form.vue'),
      },
      {
        path: 'link-checkout/:id/edit',
        name: 'seller-link-checkout-edit',
        meta: {
          activity: 'edit',
        },
        component: () => import('@/app/views/seller-page/link-checkout/desktop/Form.vue'),
      },
      {
        path: 'setting',
        name: 'seller-setting',
        component: () => import('@/app/views/seller-page/setting/index.vue'),
      },
      {
        path: 'setting/faq',
        name: 'seller-setting-faq',
        component: () => import('@/app/views/seller-page/setting/faq/index.vue'),
      },
      {
        path: 'setting/terms-and-conditions',
        name: 'seller-setting-terms-and-conditions',
        component: () => import('@/app/views/seller-page/setting/faq/terms-conditions/index.vue'),
      },
      {
        path: 'setting/privacy-policy',
        name: 'seller-setting-privacy-policy',
        component: () => import('@/app/views/seller-page/setting/faq/privacy-policy/index.vue'),
      },
      {
        path: 'live-checkout/create',
        name: 'seller-live-checkout-create',
        component: () =>
          import('@/app/views/seller-page/live-checkout/create-edit/createDesktop.vue'),
      },
      {
        path: 'live-checkout/list',
        name: 'seller-live-checkout-list',
        component: () => import('@/app/views/seller-page/live-checkout/index.vue'),
      },
      {
        path: 'live-checkout/:id/edit',
        name: 'seller-live-checkout-edit',
        component: () =>
          import('@/app/views/seller-page/live-checkout/create-edit/createDesktop.vue'),
      },
      {
        path: 'live-checkout/:id/performance-live-checkout',
        name: 'seller-performance-live-checkout',
        component: () =>
          import('@/app/views/seller-page/live-checkout/create-edit/createDesktop.vue'),
      },
      {
        path: 'live-checkout/:id',
        name: 'seller-live-checkout-view',
        component: () =>
          import('@/app/views/seller-page/live-checkout/create-edit/createDesktop.vue'),
      },
      {
        path: 'setting/payments',
        name: 'seller-setting-payments',
        component: () => import('@/app/views/seller-page/setting/payments/index.vue'),
      },
      {
        path: 'setting/payments/integration',
        name: 'seller-setting-payments-integration',
        component: () => import('@/app/views/seller-page/setting/payments/integration/index.vue'),
      },
      {
        path: 'setting/payments/integration/edit',
        name: 'seller-setting-payments-integration-edit',
        component: () => import('@/app/views/seller-page/setting/payments/integration/index.vue'),
      },
      {
        path: 'orders/upload-seller-checkout',
        name: 'upload-seller-checkout',
        component: () => import('@/app/views/seller-page/orders/upload-seller-checkout.vue'),
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/app/views/login/index.vue'),
    children: [
      {
        path: 'ref/:referralCode',
        name: 'referral-register',
        component: () => import('@/app/views/login/index.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/app/views/login/index.vue'),
    children: [
      {
        path: 'ref/:referralCode',
        name: 'referral-login',
        component: () => import('@/app/views/login/index.vue'),
      },
    ],
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/app/views/onboarding/index.vue')
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/app/layouts/seller-layout.vue'),
    name: 'NotFound',
    children: [
      {
        path: '/:catchAll(.*)',
        component: () => import('@/app/views/seller-page/not-found.vue'),
        name: 'NotFound',
      },
    ],
  },
]
